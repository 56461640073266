import { rgba } from 'polished';

export const theme = {
  font: {
    primary: `'IBM Plex Serif', serif`,
    secondary: `'IBM Plex Sans', serif`,
  },
  font_size: {
    xsmall: 'font-size: 15px; line-height: 28px',
    small: 'font-size: 17px; line-height: 30px',
    regular: 'font-size: 19px; line-height: 33px',
    large: 'font-size: 21px; line-height: 35px',
    larger: 'font-size: 32px; line-height: 48px',
    xlarge: 'font-size: 50px; line-height: 56px',
  },
  font_weight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  color: {
    white: '#FFFFFF',
    black_light: rgba('#564F62', 0.2),
    black: '#343739',
    font: {
      regular: rgba('#564F62', 0.8),
    },
    primary: '#56B3C8',
    secondary: '#DE7E69',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
    xl: '1300px',
  },
  transition: 'transition: all .14s linear;',
  transition2: 'transition: all .3s linear;',
  letter_spacing: {
    loose: '0.06em',
  },
};

export default theme;
