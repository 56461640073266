import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import SEO from '@common/SEO';

import theme from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';

const Layout = ({ children, seo }) => (
  <ThemeProvider theme={theme}>
    <>
      <SEO seo={seo} />
      <GlobalStyles />
      {children}
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seo: PropTypes.object,
};

export default Layout;
