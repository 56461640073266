import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const BACKUP_SEO_DATA = {
  description: 'Shahid Properties',
  title: 'Shahid Properties',
  url: '',
  author: 'Shahid Properties',
  keywords: ['Shahid Properties', 'Real Estate', 'Property Rental'],
  twitter: {
    id: '',
    img: '',
  },
  facebook: {
    id: '',
    img: '',
  },
};

const SEO = ({ seo }) => {
  const SEO_DATA = seo || BACKUP_SEO_DATA;

  return (
    <Helmet>
      {SEO_DATA.facebook && (
        <meta property="fb:app_id" content={SEO_DATA.facebook.id} />
      )}
      {SEO_DATA.title && <meta property="og:title" content={SEO_DATA.title} />}
      <meta property="og:type" content="website" />
      {SEO_DATA.url && <meta property="og:url" content={SEO_DATA.url} />}
      {SEO_DATA.facebook && (
        <meta property="og:image" content={SEO_DATA.facebook.img} />
      )}
      {SEO_DATA.description && (
        <meta property="og:description" content={SEO_DATA.description} />
      )}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="" />
      <meta name="twitter:site" content="" />
      {SEO_DATA.title && <meta name="twitter:title" content={SEO_DATA.title} />}
      {SEO_DATA.description && (
        <meta name="twitter:description" content={SEO_DATA.description} />
      )}
      {SEO_DATA.url && <meta name="twitter:domain" content={SEO_DATA.url} />}
      {SEO_DATA.img && <meta name="twitter:image:src" content={SEO_DATA.img} />}

      {SEO_DATA.description && (
        <meta name="description" content={SEO_DATA.description} />
      )}
      {SEO_DATA.keywords && (
        <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      )}
      {SEO_DATA.author && <meta name="author" content={SEO_DATA.author} />}
      {SEO_DATA.title && <title>{SEO_DATA.title}</title>}
      <html lang="en" />
    </Helmet>
  );
};

SEO.propTypes = {
  seo: PropTypes.object,
};

export default SEO;
